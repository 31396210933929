import React, { useCallback, useEffect, useMemo, useState, forwardRef,useImperativeHandle } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Checkbox from "@mui/material/Checkbox";
import Input from "@mui/material/Input";
import { FiSearch } from "react-icons/fi";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ColumnSettingsOptions from "./ColumnSettingsOptions";
import { useDesignsContext } from "../Context/DesignsContext";
import { useSocketioContext } from "../Context/SocketioContext";
import { useAuth } from "../Context/AuthContext";
import { useLoaderContext } from "../Context/LoadingContext";
import { useSnowFlakeContext } from "../Context/SnowFlakeContext";

const ColumnSettingsModal = forwardRef(({ currentNode = { data: {} }, authorized },ref) => {
  const [columns, setColumns] = useState([]);
  const [filteredColumns, setFilteredColumns] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const { socket } = useSocketioContext();
  const { currentDesign, designVersion } = useDesignsContext();
  const { snowflakeAccount, accountsList } = useAuth();
  const { isLoading, setIsLoading } = useLoaderContext();
  const { schemaList, dvaVersion } = useSnowFlakeContext()

  const getPDILdestSchema = useCallback(() => {
    if(snowflakeAccount === "PDIL") {
      const filterPattern = accountsList[snowflakeAccount]?.destinationSchemaFilter || ''
      const schemaFilter = new RegExp(`${filterPattern}`,  'i')
      const newSchemaList = schemaList.filter(schema => schemaFilter.test(schema))
      return newSchemaList[0]
    } else {
      return null
    }
  }, [accountsList, schemaList, snowflakeAccount])

  const getData = useCallback(() => {
    if (!currentNode?.data?.userSettings || !currentDesign?.data || !currentNode?.data?.userSettings?.objectSchema || !currentNode?.data?.userSettings?.object) {
      return;
    }
    setIsLoading(true);
    socket.emit(
      "getCastedColumns",
      {
        dbName: currentNode.data.database,
        psaSource: currentNode.data.schema,
        destSchema: currentDesign.designId,
        designDB: currentDesign?.databaseName,
        account: accountsList[snowflakeAccount].accountURL,
        objSchema: currentNode.data.userSettings.objectSchema,
        object: currentNode.data.userSettings.object,
        sourceDeletedFieldName: `${
          currentNode.data.userSettings.deletedFieldIndicator
            ? currentNode.data.userSettings.sourceDeletedFieldName
            : currentNode.data.userSettings.deletedFieldNameIndicator
        }`,
        sourceDeletedField: `${currentNode.data.userSettings.deletedFieldIndicator}`,
        skipSyshash: currentNode.data.userSettings.skipSyshash,
        isXlink: ["xlinknodefull", "xlinknodedelta"].includes(currentNode.type),
        tableId: currentNode.id,
        pdilDestinationSchema: getPDILdestSchema(),
        generatorVersion: dvaVersion,
        designVersion: designVersion
      },
      (response) => {
        const mappedColumns = Object.keys(response).map((columnName) => {
          const value = response[columnName];
          const mappedOptions = value.options.map((option) => ({
            id: option.optionID,
            selected: option.isSelect,
            name: option.text,
            deletable: true,
          }));
          return {
            name: columnName,
            valueType: value.type,
            optionList: [
              {
                id: "default",
                selected: value.options.find((option) => option.isSelect)
                  ? false
                  : true,
                name: value.defaultText,
                deletable: false,
              },
              ...mappedOptions,
            ],
          };
        });
        setColumns(mappedColumns);
        setFilteredColumns(mappedColumns);
        setIsLoading(false);
      }
    );
  }, [currentNode, currentDesign, socket, setIsLoading, snowflakeAccount, accountsList, getPDILdestSchema]);

useImperativeHandle(ref,()=>({
  getData
}));

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    setFilteredColumns(
      columns.filter((item) =>
        item.name.toLowerCase().includes(searchValue?.toLowerCase())
      )
    );
  }, [searchValue, columns]);

  const selectOption = (selectedOptionID, column) => {
    setIsLoading(true);

    socket.emit(
      "selectDesignOption",
      {
        optionId: selectedOptionID,
        account: accountsList[snowflakeAccount].accountURL,
        columnName: column.name,
        design: currentDesign.designName,
        nodeLabel: currentNode.id,
        newValue: true,
        version: designVersion
      },
      getData
    );
  };

  const deleteOption = (deletedOptionId, column) => {
    setIsLoading(true);

    socket.emit(
      "deleteDesignOption",
      {
        optionId: deletedOptionId,
        account: accountsList[snowflakeAccount].accountURL,
        columnName: column.name,
        design: currentDesign.designName,
        nodeLabel: currentNode.id,
        version: designVersion
      },
      getData
    );
  };

  const addOption = (optionText, column) => {
    setIsLoading(true);
    socket.emit(
      "addDesignOption",
      {
        optionText,
        columnName: column.name,
        account: accountsList[snowflakeAccount].accountURL,
        design: currentDesign.designName,
        nodeLabel: currentNode.id,
        optionType: column.valueType,
        version: designVersion
      },
      getData
    );
  };

  return (
    <div>
      <div className="column-settings-search">
        <FiSearch className="column-settings-search-icon" />
        <Input
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          className="column-settings-search-input"
          placeholder="Search..."
        />
      </div>
      <p className="column-settings-helper-text">
        Select data type casts for your columns
      </p>
      <div className="column-settings-accordian-container">
        {filteredColumns.map((item) => (
          <Accordion key={item.name} className="column-settings-accordion">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className="column-settings-title">
                <Checkbox
                  className="column-settings-title-checkbox"
                  indeterminate={item.optionList.find(
                    (option, index) => option.selected && index !== 0
                  )}
                  disabled
                />
                <p className="column-settings-title-text">{item.name}</p>
                <p className="column-settings-title-type">({item.valueType})</p>
              </div>
            </AccordionSummary>
            <AccordionDetails className="column-settings-details">
              <ColumnSettingsOptions
                isLoading={isLoading}
                items={item.optionList}
                selectOption={(selectedOptionID) =>
                  selectOption(selectedOptionID, item)
                }
                deleteOption={(selectedOptionID) =>
                  deleteOption(selectedOptionID, item)
                }
                addOption={(selectedOptionID) =>
                  addOption(selectedOptionID, item)
                }
                authorized={authorized}
              />
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
});

export default ColumnSettingsModal;
