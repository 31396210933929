import { useModalContext } from '../../../Context/ModalContext';
import { useEffect, useState } from 'react';
import { Handle } from 'react-flow-renderer';
import './CustomNodesStyle.css';
import SettingsIcon from '@mui/icons-material/Settings';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import Tooltip from '@mui/material/Tooltip';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  DATA,
  USER_SETTINGS,
  TABLE_ABBREVIATION,
  BUSINESS_KEY,
  DRIVING_KEY,
  SOURCE_DELETED_FIELD_INDICATOR,
  SOURCE_DELETED_FIELD_NAME,
  TALEND_DELETED_FIELD_INDICATOR,
  DRIVING_KEY_LABEL,
  OVERLOAD_SOURCE_TABLES_OBJECT,
  SELECTED_COLUMNS,
} from './NodeLinkTypes';
import { usePlaygroundContext } from '../../../Context/PlaygroundContext';

const LSatNode = (node) => {
  const { enableNodeSettingsModalShow, setNodeSettingsModalCurrentNode } = useModalContext()
  const { onElementsRemove } = usePlaygroundContext()
  const { label, enableNodeSettings } = node.data
  const [enableSettings, setEnableSettings] = useState(enableNodeSettings)
  const [overloaded, setOverloaded] = useState(false)
  const [warning, setWarning] = useState(false)
  
  const onDelete = () => {
    onElementsRemove([node])
  }
  
  useEffect(() => {
    if(enableNodeSettings) {
      if(node[DATA][USER_SETTINGS] !== undefined) {
        switch (true) {
          case (node[DATA][USER_SETTINGS][TABLE_ABBREVIATION] === undefined):
          case (node[DATA][USER_SETTINGS][TABLE_ABBREVIATION] === ''):
          case isNotValidBusinessKey(node[DATA][USER_SETTINGS][BUSINESS_KEY]):
          case (node[DATA][USER_SETTINGS][SELECTED_COLUMNS] === undefined || node[DATA][USER_SETTINGS][SELECTED_COLUMNS].length < 1):
          case (node[DATA][USER_SETTINGS][DRIVING_KEY] !== undefined && node[DATA][USER_SETTINGS][DRIVING_KEY].length < 1):
          case (node[DATA][USER_SETTINGS][SOURCE_DELETED_FIELD_INDICATOR] === undefined):
          case (node[DATA][USER_SETTINGS][TALEND_DELETED_FIELD_INDICATOR] === undefined):
          case (node[DATA][USER_SETTINGS][DRIVING_KEY_LABEL] === undefined):
          case (node[DATA][USER_SETTINGS][DRIVING_KEY_LABEL] === undefined || ''):
          case (node[DATA][USER_SETTINGS][SOURCE_DELETED_FIELD_INDICATOR] === true && node[DATA][USER_SETTINGS][SOURCE_DELETED_FIELD_NAME] === ''):
            setWarning(true);
            break
          default:
            setWarning(false)
            break;
        }
        if(!(node[DATA][USER_SETTINGS].hasOwnProperty(SELECTED_COLUMNS))){
          if((node[DATA][USER_SETTINGS][TABLE_ABBREVIATION] !== undefined) && (node[DATA][USER_SETTINGS][TABLE_ABBREVIATION] !== '') && warning === false){
            setWarning(false);
          }
        }
        
        if(node[DATA][OVERLOAD_SOURCE_TABLES_OBJECT] !== undefined) {
          const overloadedValues = Object.values(node[DATA][OVERLOAD_SOURCE_TABLES_OBJECT]);
          const overloadedSet = new Set(overloadedValues);

          if (overloadedSet.size !== overloadedValues.length) {
            setWarning(true);
          }

          overloadedValues.forEach(tableValue => {
            if (node[DATA].userSettings.object === tableValue) {
              setWarning(true)
            }
          })
        }
      } else {
        setWarning(true)
      }
    } else {
      setWarning(false)
    }
    setEnableSettings(enableNodeSettings)
  }, [ node[DATA][USER_SETTINGS], enableNodeSettings ])

  const isNotValidBusinessKey = (businessKeyDict) => {
    const businessKeyData = Object.values(businessKeyDict)
    if(businessKeyData.length < 2) return true
    for (const value of businessKeyData) {
      if (value.length < 1) return true
    }
    return false
  }

  const onClick = () => {
    setNodeSettingsModalCurrentNode(node)
    enableNodeSettingsModalShow(node.id)
  }

  useEffect(() => {
    if (node[DATA][OVERLOAD_SOURCE_TABLES_OBJECT] !== undefined && Object.keys(node[DATA][OVERLOAD_SOURCE_TABLES_OBJECT]).length > 0) {
      setOverloaded(true)
    } else setOverloaded(false)
  }, [node[DATA][OVERLOAD_SOURCE_TABLES_OBJECT]])

  return (
    <div className="lsatNode">
      <Handle
        type="target"
        position="top"
        style={{ background: '#555' }}
      />
      <div className="py-1 px-2" >
        {label}
        <span className="position-absolute top-100 start-100 translate-middle badge fs-6" hidden={!overloaded}>
          <Tooltip title="Overloaded LSAT" placement="bottom-start" arrow>
            <AutoAwesomeMotionIcon sx={{ fontSize: 20, color: '#f19d32' }} />
          </Tooltip>
        </span>
        <span className="position-absolute top-0 start-100 translate-middle badge fs-6" hidden={!warning}>
        <Tooltip 
          title="Node Settings incomplete." 
          placement="bottom-start" 
          arrow
        >
          <ErrorTwoToneIcon sx={{ fontSize: 25, color: 'red' }} />
        </Tooltip>
        </span>
        <span className="ms-2" onClick={onClick} style={{ cursor: 'pointer' }} hidden={!enableSettings}>
          <Tooltip 
            title="Node settings" 
            placement="bottom-end" 
            arrow 
          >
            <SettingsIcon style={{ fontSize: 'large', marginBottom: "0.175rem" }} />
          </Tooltip>
        </span>
        {node.selected && (
          <span className="ms-2" onClick={onDelete} style={{ cursor: 'pointer' }}>
            <Tooltip 
              title="Delete node along with its dependents" 
              placement="bottom-start" 
              arrow 
            >
              <DeleteForeverIcon style={{ fontSize: 'large', marginBottom: "0.175rem" }} />
            </Tooltip>
          </span>
          )
        }
      </div>
    </div>
  );
}

export default LSatNode;
