import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useModalContext } from "../Context/ModalContext";
import TextField  from "@mui/material/TextField";
import { useAuth } from '../Context/AuthContext';
import { useSocketioContext } from '../Context/SocketioContext';
import { useDesignsContext } from "../Context/DesignsContext";
import { useLoaderContext } from '../Context/LoadingContext';
import { ACTION_MESSAGES } from "../Types/ActionMessageTypes";
import { LOADED } from "../Types/DesignStatusTypes";

const MultiDesignModal = () => {
  // Accessing states & functions from AuthContext - (Logged user)
  const { user } = useAuth();
  const username = `${user?.attributes?.given_name} ${user?.attributes?.family_name}`;
  // To access socket for creating a room and joining
  const { socket } = useSocketioContext();
  const { setIsLoading } = useLoaderContext();
  const {showModal, multiDesignModalHide} = useModalContext();
  const [versionValue, setVersionValue] = useState("");
  const { currentDesign, setDesignVersion, setCurrentDesign } = useDesignsContext();

  const createVersion = () => {
    const actionMessage = ACTION_MESSAGES["CREATE_NEW_DESIGN_VERSION"].message({ user: username, version: versionValue});
    setIsLoading(true);
    socket.emit('createDesignVersion', {
        userId: user?.attributes?.name,
        tableId: currentDesign.designId,
        version: versionValue,
        actionMessage,
    },(response) => {
      if(response){
        setDesignVersion(versionValue);
      }
    });
    setVersionValue("");
    setCurrentDesign(prevState => {
      return {
        ...prevState, 
        savingStatus: LOADED,
        updatedByDesign: true,
        version: versionValue
      }
    })
    setIsLoading(false);
  }

  const onSave = () => {
      createVersion();
    multiDesignModalHide();
    
  };

  const onHide = () => {
    setVersionValue("");
    multiDesignModalHide();
  };

  if(!showModal) return null;
  return (
    <div onClick ={(e) => e.stopPropagation()}>
      <Modal
        show={showModal}
        onHide={onHide}
        backdrop="static"
        size="lg"
        aria-labelledby="multi-design-modal"
        centered
      >
        <Modal.Header className="border-0" closeButton>
          <Modal.Title id="multi-design-modal">
            {"Multi Design"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
        <div className="modal-body-contain">
          <p>Would you like to make a prototype version of your design?</p>
          <div className="modal-input-field">
            <label className="modal-label">Version Name</label>
            <TextField 
              value={versionValue}
              onChange={(e) => setVersionValue(e.target.value)}
              className="modal-input"
              placeholder=""
              variant="outlined"
              autoFocus
            />
          </div>          
          {!versionValue.trim() && (<p className="modal-disclaimer" style={{color : "red"}}><span>**</span>Version name field should not be empty.</p>)}
        </div>
        </Modal.Body>
        <Modal.Footer className="border-0 mx-4">
          <p className="modal-disclaimer"><span>**</span>You can make up to five different iterations of your design.</p>
          <Button 
            type="button" 
            variant="outline-primary" 
            disabled = {!versionValue.trim()}
            style={{cursor : !versionValue.trim() ? "not-allowed" : "pointer"}}
            onClick={(e)=>{
              e.stopPropagation();
              onSave();
            }}
          >
            Save
          </Button>
          <Button 
            type="button" 
            variant="outline-secondary" 
            onClick={(e)=>{
              e.stopPropagation();
              onHide();
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default MultiDesignModal;