export const ACTION_MESSAGES = {
    "ADD_NODE": {
      message: ({ user, nodeName }) =>
        `${user} added a new node: ${nodeName}.`
    },
    "ADD_EDGE": {
      message: ({ user, sourceNodeType, targetNodeType }) =>
        `${user} created an edge from ${sourceNodeType} to ${targetNodeType}.`
    },
    "NODE_DRAG": {
      message: ({ user, nodeName }) =>
        `${user} moved node ${nodeName}.`
    },
    "SATELLITE_OVERLOAD": {
      message: ({ user, nodeName, tableName }) =>
        `${user} overloaded source table for node ${nodeName} by adding table ${tableName}.`
    },
    "UPDATE_DESIGN": {
      message: ({ user, nodeName, connectionType }) =>
        `${user} added node ${nodeName} with connection type ${connectionType}.`
    },
    "UPDATE_NODE_SETTINGS": {
      message: ({ user, originalNodeName, updatedNodeName, isRename }) => {
        if (!isRename) {
          return `${user} updated node settings and renamed node from ${originalNodeName} to ${updatedNodeName}.`;
        } else {
          return `${user} updated node settings for node ${originalNodeName}.`;
        }
      }
    },
    "NODE_CLONED": {
      message: ({ user, originalNodeName }) =>
        `${user} cloned the node ${originalNodeName}.`
    },
    "NODE_PASTED": {
      message: ({ user }) =>
        `${user} pasted copied elements into the design layout.`
    },
    "REMOVE_NODE": {
      message: ({ user, originalNodeName }) =>
        `${user} removed ${originalNodeName}.`
    },
    "REMOVE_EDGE": {
      message: ({ user, sourceNodeType, targetNodeType }) =>
        `${user} removed the edge between ${sourceNodeType} and ${targetNodeType}.`
    },
    "REMOVE_CONNECTED_NODES": {
      message: ({ user, nodeName, connectedNodes }) =>
        `${user} removed the node ${nodeName} along with connected nodes: ${connectedNodes.join(', ')}.`
    },
    "DESIGN_BUSINESS_NAME_RENAMED": {
      message: ({ user, designId, updatedDesignName}) =>
        `${user} renamed business name of design ${designId} to ${updatedDesignName}.`
    },
    "CREATE_NEW_DESIGN_VERSION": {
      message: ({ user, version}) =>
        `${user} created new design version ${version} from DEFAULT design.`
    },
    "EMPTY_TEXT": {
      message: () =>
        `Version name field should not be empty.`
    }
  };
  