import { Link } from "react-router-dom"
import Stack from "react-bootstrap/Stack"
import { useDesignsContext } from "../Context/DesignsContext"
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import UndoRoundedIcon from '@mui/icons-material/UndoRounded';
import RedoRoundedIcon from '@mui/icons-material/RedoRounded';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Tooltip from '@mui/material/Tooltip'
import { Typography } from "@mui/material"
import { UNDO, REDO } from '../Types/ActionTypes';
import { AUTOSAVING, LOADING, SYNCING } from '../Types/DesignStatusTypes'
import { USER_TUTORIAL } from "../Types/MenuTypes"
import { useEffect, useState } from "react"
import { useSocketioContext } from "../Context/SocketioContext"
import { useAuth } from "../Context/AuthContext"
import TransparentTextField from "./TransparentTextField"
import LogoutDropdown from './LogoutDropdown';
import { ReactComponent as RocheLogoWhite } from '../Assets/svg/RocheLogoWhite.svg';
import Badge from '@mui/material/Badge'; 
import CustomDropdown from "./CustomDropdown";
import MultiDesignModal from "./MultiDesignModal";
import { useModalContext } from "../Context/ModalContext";
import { usePlaygroundContext } from '../Context/PlaygroundContext';
import { useLoaderContext } from '../Context/LoadingContext';
import { DEFAULT_DESIGN, MAX_DESIGN_VERSION_LIMIT } from "../Constant/DesignVersion";
import classNames from "classnames";

const ActionBar = ({ activeStep, toggleHistorySidebar, isHistorySidebarOpen }) => {
  const [unFilteredVersionList, setUnFilteredVersionList] = useState([])
  const { socket } = useSocketioContext()
  const { currentDesign, openDesign, designRename, currentDesignActiveUsers, setCurrentDesignActiveUsers, undoDesign, redoDesign, designVersionList, setDesignVersionList, actionCount, setActionCount, designVersion, setDesignVersion } = useDesignsContext()
  const { setElements } = usePlaygroundContext();
  const { snowflakeAccount, user } = useAuth();
  const [showBadge, setShowBadge] = useState(true);
  const { multiDesignModalShow} = useModalContext();  
  const { setIsLoading } = useLoaderContext();

  const syncStatusMessage = "Designing Sync Status";
  useEffect(() => {
    if (socket !== null && socket !== undefined) {
      socket.on('updateDesignActiveUsers', (usersList) => {
        setCurrentDesignActiveUsers(usersList);
      });
      socket.on('receiveDesignVersionList', ({versionList}) => {
        setUnFilteredVersionList(versionList)
      })
    }
  }, [socket]);

  useEffect(()=> {
    if (actionCount > 0) {
      setShowBadge(true)
    }
  },[actionCount])

  useEffect(() => {    
    const designData = currentDesign?.data?.elements?.length > 0 ? currentDesign?.data?.elements : [];
    setElements([...designData]);
    setIsLoading(false);
  }, [currentDesign])

  useEffect(() => {
    if(isHistorySidebarOpen) {
      toggleHistorySidebar()
    } 
    openDesign(currentDesign.designId, undefined, designVersion);
  }, [designVersion])

  useEffect(() => {
    setUnFilteredVersionList((prevValue) => {
      if(prevValue.length) {
        loadFilteredDesignVersionList(prevValue, designVersion)
      }
      return prevValue
    })
  }, [unFilteredVersionList, designVersion])

  useEffect(() => {
    socket?.emit('getDesignVersionList', {
      userId: user?.attributes?.name,
      tableId: currentDesign.designId,
    })
  }, [])

  // Function to handle onClick event on multiple actions in the action bar
  const handleClick = async (option) => {
    switch (option) {
      case UNDO:
        undoDesign(designVersion)
        break
      case REDO:
        redoDesign(designVersion)
        break
      default:
        break;
    }
  }

  const handleHistoryClick = () => {
    if (isHistorySidebarOpen) {
      setShowBadge(false);  
      setActionCount(0)
    }
    toggleHistorySidebar();  
  }

  const handleVersionClick = (value) =>{
    setDesignVersion(value);
  }

  const loadFilteredDesignVersionList = (versionList, selectedOption) => {
    let filteredVersionList = versionList.filter((item) => {
      return !(item === DEFAULT_DESIGN || item === selectedOption)
    })
    if(selectedOption !== DEFAULT_DESIGN) {
      filteredVersionList.unshift(DEFAULT_DESIGN)
    }
    setDesignVersionList(filteredVersionList)
  }

  const handleVersionDelete = (deletedValue) => {
    if (window.confirm(`Do you want to delete the design version ${deletedValue}?`)) {
      setIsLoading(true);
      socket.emit('deleteDesignVersion', {
        userId: user?.attributes?.name,
        tableId: currentDesign.designId,
        version: deletedValue,
        room: `${currentDesign.designId}__${deletedValue}`
      });
      setIsLoading(false);
    }
  }

  const handleAddIcon = (buttonDisabled) => {
    if(!buttonDisabled) {
      multiDesignModalShow();
    }
  }
  const showUndoAndRedoButtons = currentDesign.authorized && (activeStep === 0)
  const maxVersionsLimitReached = designVersionList.length >= MAX_DESIGN_VERSION_LIMIT ? true : false
  const disableAddVersionButton = (currentDesign?.authorized && activeStep === 0) ? false : true
  return (
    <Stack direction="horizontal" gap={2} className="px-4 bg-primary text-white h-100">
      <Link to="/" >
        <span role="button">
          <RocheLogoWhite className="navbar-logo" />
        </span>
      </Link>
      <div className="ms-4">
        {currentDesign.designName !== USER_TUTORIAL
          ? (currentDesign.version === DEFAULT_DESIGN
            ? (
              <TransparentTextField
                inputText={currentDesign?.businessName}
                updateTextHandler={value => designRename(value)}
                authorized={currentDesign?.authorized}
              />
            )
            : (
              <Tooltip
                title='Team name can be renamed from DEFAULT design only.'
                placement="bottom"
                arrow
              >
                {currentDesign?.businessName}
              </Tooltip>
            )
          )
          : `SANDBOX ENVIRONMENT in ${snowflakeAccount}`}
      </div>
      {currentDesign.designName !== USER_TUTORIAL && (
        <div role="button" className='ms-4 pe-1'>
          <Tooltip
            title={currentDesignActiveUsers?.length > 0 ? (
              currentDesignActiveUsers.join(', ')
            ) : 'No more users working on this design'}
            placement="bottom"
            arrow
          >
            <PeopleAltIcon />
          </Tooltip>
        </div>
      )}

      <div className="ms-auto multi-design-select">
        <CustomDropdown
          dropdownList={designVersionList}
          dropdownName="Multi Design List"
          handleSelection={(value) => {
            handleVersionClick(value)
          }}
          onDelete={(deletedValue, e) => {
            e.stopPropagation();
            handleVersionDelete(deletedValue)
          }}
          selectedValue={designVersion}
          multiDesign={true}
          clipLength={30}
          needToBeAuthorized={false}
          customClassname="custom-dropdown-styles"
          freeze= {activeStep === 0 ? false : true  }
        />

      </div>
      <Tooltip 
        title={
          currentDesign?.authorized ? (
            maxVersionsLimitReached ? "You have already 5 versions of your design" : "Create a new design version from DEFAULT design"
          ) : "You are not authorized to add new version"
        } 
        placement="bottom-start" 
        arrow
      >
        <span
          role="button" 
          className={classNames("ms-2 pe-1", {"multi-design-add-button-not-allowed": disableAddVersionButton || maxVersionsLimitReached})} 
          onClick={() => handleAddIcon(disableAddVersionButton || maxVersionsLimitReached)}
        >
          <AddCircleIcon />
        </span>
      </Tooltip>
      <MultiDesignModal />
      {showUndoAndRedoButtons ? (
        <>
          <div role="button" className="ms-2" onClick={() => handleClick(UNDO)}>
            <Tooltip title="Undo" placement="bottom-end" arrow>
              <UndoRoundedIcon />
            </Tooltip>
          </div>
          <div role="button" className='ms-1 pe-1' onClick={() => handleClick(REDO)}>
            <Tooltip title="Redo" placement="bottom-start" arrow>
              <RedoRoundedIcon />
            </Tooltip>
          </div>
          <div className="vr"></div>
        </>) : <div className="ms-2"></div>
      }

      <div className={currentDesign.authorized ? 'ms-2' : 'ms-auto'}>
        <Tooltip 
          title={syncStatusMessage} 
          placement="bottom" 
          arrow 
        >
          <div
            className="d-inline-block"
            style={{ cursor: 'pointer' }} 
          >
            <Typography>{currentDesign.savingStatus}</Typography> 
          </div>
        </Tooltip>
      </div>
      
      {
        (currentDesign.savingStatus === AUTOSAVING || 
          currentDesign.savingStatus === LOADING || 
          currentDesign.savingStatus === SYNCING) ? (
          <>
            <div className="spinner-border spinner-border-sm ms-1 me-2">
              <span className="visually-hidden">Loading...</span>
            </div>
          </>
        ) : (
          <div className="me-2">
            <div
              className="d-inline-block"
              style={{ cursor: 'pointer' }} 
              onClick={handleHistoryClick}  // Hide badge when clicked
            >
              <Tooltip title="View design change history." placement="bottom" arrow>
              <Badge 
                badgeContent={actionCount > 0 ? actionCount : null}  
                color="secondary" 
                invisible={actionCount <= 0 || !showBadge || isHistorySidebarOpen}  
              >
                <HistoryRoundedIcon />
              </Badge>
             
              </Tooltip>
            </div>
          </div>
        )
      }

      <div>
        <LogoutDropdown top="45" />
      </div>
    </Stack>
  );
}

export default ActionBar;
