import { useState, useContext, createContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { USER_TUTORIAL } from '../Types/MenuTypes';
import { GlobalNotificationHandle } from '../Logic/NotificationHandler';
import { useDesignsContext } from './DesignsContext';
import { useSocketioContext } from './SocketioContext';

const ModalContext = createContext(null)

export const ModalProvider = ({ children }) => {
  const navigate = useNavigate();
  const { socket } = useSocketioContext()
  const { currentDesign } = useDesignsContext()
  const [nodeSettingsModalShow, setNodeSettingsModalShow] = useState(false);
  const [nodeSettingsModalCurrentNode, setNodeSettingsModalCurrentNode] = useState(null);  
  const [showModal, setShowModal] = useState(false);
  const [profileModal, setProfileModal] = useState(false);
  const [userSettingsModal, setUserSettingsModal] = useState(false);

  const userSettingsModalHide = () => setUserSettingsModal(false);

  const userSettingsModalShow = () => setUserSettingsModal(true);

  const multiDesignModalShow = () => {
    setShowModal((prevOpen) => !prevOpen);
  }

  const multiDesignModalHide = () => {
    setShowModal((prevOpen) => !prevOpen);
  }

  const profileModalHide = () => {
    setProfileModal(false);
    navigate(-1);
  }

  const profileModalShow = () => setProfileModal(true);

  const enableNodeSettingsModalShow = (nodeId) => {
    if(currentDesign?.designId === USER_TUTORIAL) {
      setNodeSettingsModalShow(true)
    } else {
      socket.emit('nodeSettingsOpen', {
        tableId: currentDesign?.designId, 
        nodeId
      }, (userWorkingOnNodeSettings) => {
        if(userWorkingOnNodeSettings === '') {
          setNodeSettingsModalShow(true)
        } else {
          GlobalNotificationHandle({ key: 'ALREADY_IN_USE', messageFiller: {user: userWorkingOnNodeSettings} })
          setNodeSettingsModalCurrentNode(null)
        }
      })
    }
  }

  return (
    <ModalContext.Provider 
      value={{ enableNodeSettingsModalShow, nodeSettingsModalShow, setNodeSettingsModalShow, nodeSettingsModalCurrentNode, setNodeSettingsModalCurrentNode, profileModal, profileModalShow, profileModalHide, setProfileModal, userSettingsModal, userSettingsModalHide, userSettingsModalShow, showModal, multiDesignModalShow, multiDesignModalHide }}
    >
      {children}
    </ModalContext.Provider>
  )
}

export const useModalContext = () => {
  return useContext(ModalContext)
}